import styled, { css } from 'styled-components';
export type StyledHeaderButtonProps = {
  variant?: 'grey' | 'cyan';
  isMouseDown?: boolean;
  loading?: boolean;
};

const variantModifiers = {
  grey: css`
    background: #d9d9d9;
    color: #4f4f4f;

    &:hover {
      background: #ededed;
    }
  `,
  cyan: css`
    background: #2cd1c0;
    color: #4f4f4f;

    &:hover {
      background: #30ead7;
    }
  `,
};

export const Button = styled.button<StyledHeaderButtonProps>`
  border: none;
  cursor: pointer;
  padding: 9px 24px;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;

  &:focus {
    outline: unset;
  }

  ${({ variant = 'grey' }) => variantModifiers[variant]};

  ${({ isMouseDown = false }) =>
    isMouseDown &&
    css`
      transform: scale(0.97);
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25) inset;
    `};
`;
