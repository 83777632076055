import { Margin, marginModifier } from '@app/utils/modifiers';
import styled, { css } from 'styled-components';

export interface StyledFormControlProps {
  variant?: 'primary' | 'secondary';
  width?: string;
  margin?: Margin;
}

const variantModifier = {
  primary: () => css``,
  secondary: () => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
};

export const Container = styled.div<StyledFormControlProps>`
  margin-bottom: 26px;
  ${({ margin }) => marginModifier(margin)};

  width: ${({ width }) => width || 'unset'};

  ${({ variant = 'primary' }) => variantModifier[variant]};
`;

export const TextContainer = styled.div`
  display: flex;
`;

export const ChildrenContainer = styled.div`
  display: contents;
  width: 100%;
`;

export const RequiredLabel = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 900;
  font-size: 16px;
  margin-left: 5px;
`;
