import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface AuthProps {
  requiredAuthRoute?: () => void;
}

function useAuth({ requiredAuthRoute }: AuthProps) {
  const userData: any = useSelector<any>((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(!!Object.keys(userData).length);

  const checkIsLoggedIn = async () => {
    if (Object.keys(userData).length) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      requiredAuthRoute && requiredAuthRoute();
    }
  };

  useEffect(() => {
    checkIsLoggedIn();
  }, [userData]);

  return { isLoggedIn, userData };
}

export default useAuth;
