import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { Text } from '@app/components/atoms';

import * as Styled from './styles';
import { TextProps } from '@app/components/atoms/Text';
import { Margin } from '@app/utils/modifiers';

/**
 * Form Control
 */
export interface FormControlProps
  extends Styled.StyledFormControlProps,
    HTMLAttributes<HTMLDivElement> {
  required?: boolean;
  isPrice?: boolean;
  label?: string;
  infoText?: string | ReactNode;
  labelVariant?: TextProps['variant'];
  staticTitle?: boolean;
  margin?: Margin;
}

const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  (
    {
      required = false,
      children,
      label,
      isPrice,
      infoText,
      labelVariant = 'lg',
      variant,
      width,
      staticTitle = false,
      margin,
      ...rest
    }: FormControlProps,
    ref,
  ) => {
    return (
      <Styled.Container
        margin={margin}
        variant={variant}
        width={width}
        ref={ref}
        data-testid="formControl"
      >
        {staticTitle && (
          <Styled.TextContainer {...rest}>
            <Text variant={labelVariant} margin={{ bottom: 10 }}>
              {label}
            </Text>
          </Styled.TextContainer>
        )}
        {infoText}
        {children && (
          <Styled.ChildrenContainer>
            {React.cloneElement(children as React.ReactElement, {
              placeholder: !staticTitle ? label : null,
              required,
              isPrice,
              staticTitle,
            })}
          </Styled.ChildrenContainer>
        )}
      </Styled.Container>
    );
  },
);

export default FormControl;
