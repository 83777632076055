import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQuery } from '@app/hooks';
import { USER_INTAKE } from '@app/queries/userIntake';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Img,
  LinkButton,
  Input,
  Button,
  TextArea,
} from '@app/components/atoms';

import { footerValidation } from './footerValidation';

import * as Styled from './styles';
import Link from 'next/link';
import useAuth from '@app/hooks/useAuth';
import FormControl from '../FormControl';
import RequiredText from '../RequiredText';
import * as Sentry from '@sentry/nextjs';

/**
 * Footer
 */
export interface FooterProps {
  footerRef: any;
  wide?: boolean;
  smallFooter?: boolean;
}

const Footer = ({ footerRef, wide, smallFooter }: FooterProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useAuth({});
  const theme = useTheme();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(footerValidation),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      textMessage: '',
    },
  });

  const name = watch('name');

  const handleSaveSuccess = () => {
    setLoading(false);
    setFormSubmitted(true);
  };

  const userIntakeMutation = useQuery({
    query: USER_INTAKE,
    onResult: handleSaveSuccess,
    onFail: (err) => Sentry.captureException(err),
    useIsLoading: true,
    authMode: 'API_KEY',
  });

  const submitForm = (values: any) => {
    setLoading(true);
    try {
      userIntakeMutation.execute({
        channel: `#${process.env.NEXT_PUBLIC_CONTACT_SLACK_CHANNEL}`,
        message: JSON.stringify(values),
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Styled.Container>
      <Styled.Content wide={wide} smallFooter={smallFooter}>
        <Styled.ColumnsContainer smallFooter={smallFooter}>
          {/* Left Column */}
          <Styled.ColumnContainer>
            <Styled.LogoTextWrapper>
              <Styled.ImageContainer>
                <Link href="/" passHref>
                  <Img
                    src="images/logo2.png"
                    layout="fill"
                    objectFit="contain"
                    alt="Trusty - Home Matching Network"
                  />
                </Link>
              </Styled.ImageContainer>
              <Styled.TextColumn>
                <Styled.NormalText>
                  Trusty is an exclusive agent network, utilizing automated
                  market intelligence to strengthen your relationships and grow
                  your business.
                </Styled.NormalText>
              </Styled.TextColumn>
            </Styled.LogoTextWrapper>

            <Styled.ButtonsWrapper smallFooter={smallFooter}>
              {isLoggedIn && (
                <Styled.ButtonContainer>
                  <LinkButton href="/account">Account</LinkButton>
                </Styled.ButtonContainer>
              )}

              {!isLoggedIn && (
                <Styled.ButtonContainer>
                  <LinkButton href="/invite">Invite your agent</LinkButton>
                </Styled.ButtonContainer>
              )}

              <Styled.ButtonContainer>
                <LinkButton href="/pricing">Pricing</LinkButton>
              </Styled.ButtonContainer>

              {!isLoggedIn && (
                <>
                  <Styled.ButtonContainer>
                    <LinkButton href="/pricing">Sign Up</LinkButton>
                  </Styled.ButtonContainer>
                  <Styled.ButtonContainer>
                    <LinkButton href="/login">Log In</LinkButton>
                  </Styled.ButtonContainer>
                </>
              )}
            </Styled.ButtonsWrapper>

            <Styled.RowCont hideMobile>
              <LinkButton href="/terms-of-use">Terms of Use</LinkButton>
              <Styled.DotSeparator>•</Styled.DotSeparator>
              <LinkButton href="/privacy-policy">Privacy Policy</LinkButton>
            </Styled.RowCont>

            <Styled.RowCont bigMargin hideMobile>
              <Styled.Text>
                © Trusty Labs, Inc. All rights reserved,{' '}
                {new Date().getFullYear()}
              </Styled.Text>
              <Styled.DotSeparator>•</Styled.DotSeparator>
              <Styled.Text>California DRE #01527508</Styled.Text>
            </Styled.RowCont>
          </Styled.ColumnContainer>
          {/* Right Column */}
          <Styled.ColumnContainer ref={footerRef}>
            <Styled.ContactText>Contact Trusty</Styled.ContactText>

            {!formSubmitted && (
              <Styled.RowCont>
                <FontAwesomeIcon
                  icon={['fas', 'envelope']}
                  color="#4f4f4f"
                  style={{ marginRight: 7, cursor: 'pointer' }}
                />
                trusty@trustyhomes.com
              </Styled.RowCont>
            )}

            <Styled.Form
              onSubmit={handleSubmit(submitForm)}
              smallFooter={smallFooter}
            >
              {!formSubmitted ? (
                <>
                  <FormControl label="Your Name" required variant="secondary">
                    <Input {...register('name')} id="name" />
                  </FormControl>

                  <FormControl label="Email" required variant="secondary">
                    <Input {...register('email')} id="email" />
                  </FormControl>

                  <FormControl label="Phone" required variant="secondary">
                    <Input
                      mask="999-999-9999"
                      {...register('phone')}
                      id="phone"
                    />
                  </FormControl>

                  <FormControl label="Message" required variant="secondary">
                    <TextArea
                      rows={6}
                      {...register('textMessage')}
                      id="message"
                    />
                  </FormControl>

                  <Styled.ButtonRow>
                    <RequiredText />
                  </Styled.ButtonRow>

                  <Styled.ButtonRow>
                    <Button type="submit" variant="tertiary" loading={loading}>
                      Submit
                    </Button>
                  </Styled.ButtonRow>

                  <Styled.ButtonRow>
                    <Styled.ErrorContainer>
                      {errors.name || errors.email || errors.textMessage ? (
                        <Styled.ErrorText>
                          {errors.name?.message ||
                            errors.email?.message ||
                            errors.textMessage?.message}
                          <FontAwesomeIcon
                            icon={['far', 'exclamation-triangle']}
                            color={theme.colors.secondary}
                            style={{ marginLeft: 13 }}
                          />
                        </Styled.ErrorText>
                      ) : null}
                    </Styled.ErrorContainer>
                  </Styled.ButtonRow>
                </>
              ) : (
                <Styled.ThankContainer>
                  <Styled.ThankYouTitle>Thank you {name}!</Styled.ThankYouTitle>

                  <Styled.ThankYouMessage>
                    A Trusty team member will review your information and reach
                    out to you shortly.
                  </Styled.ThankYouMessage>
                  <Styled.ThankYouMessage margin>
                    In the meantime, feel free to email or call us.
                  </Styled.ThankYouMessage>

                  <Styled.ThankYouEmail>
                    <FontAwesomeIcon
                      icon={['far', 'envelope']}
                      color="#4f4f4f"
                      style={{ marginRight: 15 }}
                    />
                    trusty@trusyhomes.com
                  </Styled.ThankYouEmail>
                </Styled.ThankContainer>
              )}
            </Styled.Form>
          </Styled.ColumnContainer>
        </Styled.ColumnsContainer>

        <Styled.MobileWrapper>
          <Styled.RowCont>
            <LinkButton href="/terms-of-use">Terms of Use</LinkButton>
            <Styled.DotSeparator>•</Styled.DotSeparator>
            <LinkButton href="/privacy-policy">Privacy Policy</LinkButton>
          </Styled.RowCont>

          <Styled.ColumnCont>
            <Styled.SmallText>
              © Trusty Labs, Inc. All rights reserved,{' '}
              {new Date().getFullYear()}
            </Styled.SmallText>
            <Styled.SmallText>California DRE #01527508</Styled.SmallText>
          </Styled.ColumnCont>
        </Styled.MobileWrapper>
      </Styled.Content>
    </Styled.Container>
  );
};

export default Footer;
