import React, { forwardRef, ButtonHTMLAttributes, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

/**
 * Header Button
 */
export interface HeaderButton
  extends Omit<Styled.StyledHeaderButtonProps, 'isMouseDown'>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  padding?: string;
  fontSize?: number;
  width?: string;
}

const HeaderButton = forwardRef<HTMLButtonElement, HeaderButton>(
  (
    { children, width, loading, padding, fontSize, ...rest }: HeaderButton,
    ref,
  ) => {
    const [isMouseDown, setMouseDown] = useState(false);

    const handleMouseDown = () => {
      setMouseDown(true);
    };

    const handleMouseUp = () => {
      setMouseDown(false);
    };

    return (
      <Styled.Button
        ref={ref}
        {...rest}
        isMouseDown={isMouseDown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {children}
        {loading && (
          <FontAwesomeIcon
            icon={['fad', 'spinner-third']}
            size="1x"
            spin
            style={{ marginLeft: 10 }}
          />
        )}
      </Styled.Button>
    );
  },
);

export default HeaderButton;
