import React, { useState, ReactNode } from 'react';
import Image from 'next/image';

import * as Styled from './styles';

/**
 * My Trusty Nav
 */
export interface MyTrustyNavProps {
  selected: 'clients' | 'properties' | undefined;
  selectedIndex: number | undefined;
  breadcrumb?: {
    title: string;
    url?: string;
    child?: {
      title: string;
    };
  };
  buttonComponent?: ReactNode;
  loadingButtonComponent?: ReactNode;
  loading?: boolean;
}

const MyTrustyNav = ({
  selected,
  selectedIndex,
  breadcrumb,
  buttonComponent,
  loadingButtonComponent,
  loading,
}: MyTrustyNavProps) => {
  const NAV = [
    {
      name: 'clients',
      label: 'Buyers',
      href: 'buyers',
      show: true,
    },
    {
      name: 'properties',
      label: 'Properties',
      href: 'properties',
      show: true,
    },
  ];

  const [active, setActive] = useState(selected);
  const [offset, setOffset] = useState<number | undefined>(
    selectedIndex ? 115 * (selectedIndex - 1) : 0,
  );

  const clickButton = (name: any) => {
    const el = document.getElementById(`nav-bar-${name}`);
    setOffset(el?.offsetLeft);
    setActive(name);
  };

  return (
    <>
      <Styled.Container navItems={NAV.length} row itemsCenter>
        {selected ? <Styled.SelectedBox offset={offset} /> : null}

        {NAV.map((item, idx) =>
          item?.show ? (
            <Styled.NavItem
              key={idx}
              active={active === item?.name}
              id={`nav-bar-${item?.name}`}
              onClick={() => clickButton(item?.name)}
              href={`/my-trusty/${item.href}`}
            >
              {item?.label}
            </Styled.NavItem>
          ) : null,
        )}
      </Styled.Container>
      <Styled.BreadcrumbWrapper
        row
        justifyBetween={Boolean(breadcrumb)}
        justifyEnd={!Boolean(breadcrumb)}
      >
        {breadcrumb && (
          <Styled.BreadcrumbContainer row itemsCenter>
            <Styled.BreadcrumbText href={`/my-trusty/${breadcrumb?.url}`}>
              {breadcrumb?.title}
            </Styled.BreadcrumbText>
            {breadcrumb?.child && (
              <>
                <Image
                  src="/images/chevonRightOutlined.svg"
                  width={6}
                  height={18}
                  alt="arrow right"
                />
                <Styled.BreadCrumbChildText variant="sm" color="#4F4F4F">
                  {breadcrumb?.child?.title}
                </Styled.BreadCrumbChildText>
              </>
            )}
          </Styled.BreadcrumbContainer>
        )}
        {buttonComponent && (
          <>
            {loading && loadingButtonComponent
              ? loadingButtonComponent
              : buttonComponent}
          </>
        )}
      </Styled.BreadcrumbWrapper>
    </>
  );
};

export default MyTrustyNav;
