import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
`;

export const Options = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  position: absolute;
  right: 0;
  background-color: #fff;
  min-width: 100px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 18px 24px;
  z-index: 1;
`;

export const DropDown = styled.div`
  color: #4f4f4f;
  font-family: 'Inter';
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  &:focus-within,
  &:hover {
    color: #2f859b;
  }
  position: relative;
  display: inline-block;
  &:hover ${Options}, &:focus-within ${Options} {
    visibility: visible;
    opacity: 1;
    color: #4f4f4f;
  }
`;

export const Anchor = styled.a<{ active?: boolean }>`
  margin-top: 10px;
  color: ${(props) => (props.active ? '#2CD1C0' : '#4f4f4f')};
  font-family: 'Inter';
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16;
  text-decoration: none;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    color: #2f859b;
  }
`;

export const IconSpan = styled.span`
  margin-left: 10px;
`;
