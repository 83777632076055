import React from 'react';
import { Text } from '@app/components/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

/**
 * Required Text
 */
export interface RequiredTextProps {}

const RequiredText = ({ ...rest }) => {
  return (
    <Text textAlign="right" margin={{ bottom: 10, top: 10 }} {...rest}>
      <Styled.RequiredText>
        <FontAwesomeIcon icon={['far', 'asterisk']} size="sm" />
      </Styled.RequiredText>
      Required
    </Text>
  );
};

export default RequiredText;
