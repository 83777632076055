import styled from 'styled-components';

export const Container = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: #ffffff;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border: none;
`;
