import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';
import { useQuery } from '@app/hooks';
import { CREATE_PORTAL_SESSION } from '@app/queries/createPortalSession';
import * as Sentry from '@sentry/nextjs';

/**
 * Header Select
 */
interface HeaderSelectAuthProps {
  subscriptionValid?: boolean;
}

const HeaderSelectAuth = ({ subscriptionValid }: HeaderSelectAuthProps) => {
  const userData: any = useSelector<any>((state) => state.user);

  const createPortalSession = useQuery({
    query: CREATE_PORTAL_SESSION,
    onResult: ({ createPortalSession }: any) => {
      window.location.href = createPortalSession;
    },
    onFail: (err) => {
      Sentry.captureException(err);
    },
  });

  const handleManageSubscription = () => {
    createPortalSession.execute();
  };

  return (
    <Styled.DropDown>
      <span>Welcome, {userData?.firstName}</span>
      <Styled.IconSpan>
        <FontAwesomeIcon icon={faCaretDown} size="sm" />
      </Styled.IconSpan>
      <Styled.Options>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Styled.Anchor href="/account">Account</Styled.Anchor>
          {userData?.userType && userData?.userType === 'agent' && (
            <Styled.Anchor href="/account/agent-details">
              Agent details
            </Styled.Anchor>
          )}
          {userData?.userType === 'agent' && (
            <Styled.Anchor
              href={subscriptionValid ? '#' : '/pricing'}
              onClick={subscriptionValid ? handleManageSubscription : undefined}
            >
              Manage subscription
            </Styled.Anchor>
          )}

          <Styled.Anchor href="/logout">Logout</Styled.Anchor>
        </div>
      </Styled.Options>
    </Styled.DropDown>
  );
};

export default HeaderSelectAuth;
